import { FC, useEffect, useState } from 'react'

import { BoxProps, FormControl, IconContainerProps, Rating, Stack, StyledEngineProvider, Typography } from '@mui/material'

import { Answer, NpsScoreQuestionUiModel, SurveySelection, SurveyStyleUiModel } from '@feeba/types/lib'
import { styled } from '@mui/material/styles'

export const NpsScoreComponent: FC<Props> = ({ item, sx, onUpdated, key, style }) => {
    const [rating, setRating] = useState<number | null>(10)

    const textEntryBoldTextStyle = {
        fontWeight: style.isQuestionBold ? 'bold' : 'normal', // Set the font weight conditionally
        fontFamily: style.fontFamily,
        fontSize: style.questionFontSize
    }

    useEffect(() => {}, [])

    const StyledRating = styled(Rating)(({ theme }) => ({
        '& .MuiRating-iconFilled': {
            color: '#767e84',
            opacity: 1
        },
        '& .MuiRating-iconHover': {
            color: '#767e84',
            opacity: 1
        },
        '& .MuiRating-iconEmpty': {
            color: '#767e84',
            opacity: 1
        }
    }))

    const customIcons: {
        [index: string]: {
            label: string
        }
    } = {
        1: {
            label: '1'
        },
        2: {
            label: '2'
        },
        3: {
            label: '3'
        },
        4: {
            label: '4'
        },
        5: {
            label: '5'
        },
        6: {
            label: '6'
        },
        7: {
            label: '7'
        },
        8: {
            label: '8'
        },
        9: {
            label: '9'
        },
        10: {
            label: '10'
        }
    }

    const handleInputChange = (answer: Answer, value: number) => {
        setRating(value)
        onUpdated({
            questionId: item.id,
            answers: [{ answerId: answer.id, customValue: `${value}` }]
        })
    }

    function IconContainer(props: IconContainerProps) {
        const { value, ...other } = props
        return (
            <span
                style={{
                    padding: '10px 12px',
                    border: '1px solid #63cd91',
                    fontSize: '16px',
                    marginRight: '5px',
                    ...(value === rating && {
                        backgroundColor: '#63cd91',
                        color: 'white'
                    })
                }}
                {...other}
            >
                {customIcons[value].label}
            </span>
        )
    }

    return (
        <Stack direction={'column'} sx={sx} key={key}>
            <Typography variant={'h6'} style={textEntryBoldTextStyle}>
                {item.question.text}
            </Typography>
            <FormControl style={{ marginTop: '5px', display: 'block' }}>
                {item.answers.map((a, i) => {
                    return (
                        <StyledEngineProvider key={`nps-score-${key}-${i}`}>
                            <StyledRating
                                name={`nps-score-${key}`}
                                defaultValue={5}
                                max={10}
                                IconContainerComponent={IconContainer}
                                getLabelText={(value: number) => customIcons[value].label}
                                highlightSelectedOnly
                                onChange={(event, newValue) => {
                                    handleInputChange(a, newValue || 0)
                                }}
                            />
                        </StyledEngineProvider>
                    )
                })}
            </FormControl>
        </Stack>
    )
}

interface Props extends BoxProps {
    item: NpsScoreQuestionUiModel
    onUpdated: (selection: SurveySelection) => void
    key: string | number
    style: SurveyStyleUiModel
}
