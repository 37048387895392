const TAG = 'Feeba'

enum LogLevel {
    NONE = 0,
    DEBUG = 1,
    WARN = 2,
    ERROR = 3
}

export const Logger = {
    minLevel: LogLevel.NONE | LogLevel.DEBUG | LogLevel.WARN | LogLevel.ERROR,
    constructor(minLevel = LogLevel.DEBUG) {
        this.minLevel = minLevel
    },
    debug(...args) {
        if (this.minLevel >= LogLevel.DEBUG) {
            console.log('%cFeeba:', 'color: green; background-color: black;', ...args)
        }
    },

    warn(...args) {
        if (this.minLevel >= LogLevel.WARN) {
            console.warn('%cFeeba::', 'color: yellow; background-color: black;', ...args)
        }
    },

    error(...args) {
        if (this.minLevel >= LogLevel.ERROR) {
            console.error('%cFeeba::', 'color: red; background-color: black;', ...args)
        }
    }
}
