import { Answer, ItemType, MatrixTableQuestionUiModel, Statement, SurveySelection, SurveyStyleUiModel } from '@feeba/types'
import { BoxProps, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { FC, useState } from 'react'

export const MatrixTableComponent: FC<Props> = ({ sx, item, onUpdated, style }) => {
    const [state, setState] = useState<Record<string, Answer>>({})

    const questionBoldTextStyle = {
        fontWeight: style.isQuestionBold ? 'bold' : 'normal', // Set the font weight conditionally
        fontFamily: style.fontFamily,
        fontSize: style.questionFontSize
    }

    const answerBoldTextStyle = {
        fontWeight: style.isAnswerBold ? 'bold' : 'normal',
        fontFamily: style.fontFamily,
        fontSize: style.answerFontSize
    }

    const update = (statement: Statement, answer: Answer | null) => {
        const newState = { ...state }

        console.log('newState --> ', newState)
        if (answer === null) delete newState[statement.id]
        else newState[statement.id] = answer
        setState(newState)
        onUpdated({
            questionId: item.id,
            answers: [],
            selections: Object.entries(newState).map(([id, answer]) => ({ id, answer }))
        })
    }

    let answersBody: React.ReactNode = null
    switch (item.type) {
        case ItemType.MatrixTable:
            answersBody = (
                <Stack direction="column" spacing={0.5} divider={<Divider sx={{ opacity: 0.3 }} />}>
                    {item.statements.map((statement, index: number) => {
                        return (
                            <Stack direction="row" key={index}>
                                <Typography style={{ ...answerBoldTextStyle, display: 'flex', alignItems: 'center' }} color="textPrimary" sx={{ width: '100%', flexGrow: 1 }}>
                                    {statement.text}
                                </Typography>
                                <ToggleButtonGroup
                                    sx={{ pl: 3 }}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    size="small"
                                    aria-label="device"
                                    exclusive
                                    onChange={(_, answer) => update(statement, answer)}
                                    value={state[statement.id]}
                                >
                                    {item.answers.map((answer, i, arr) => {
                                        return (
                                            <ToggleButton
                                                value={answer}
                                                key={i}
                                                sx={{
                                                    width: '55px',
                                                    height: '35px',
                                                    // color: 'white',
                                                    // backgroundColor: getButtonColor(i, arr.length, style),
                                                    '&.MuiToggleButton-root': {
                                                        color: 'black',
                                                        backgroundColor: 'transparent'
                                                    },
                                                    '&.MuiToggleButton-root.Mui-selected': {
                                                        color: 'white',
                                                        backgroundColor: getButtonColor(i, arr.length, style)
                                                    }
                                                }}
                                            >
                                                {answer.text}
                                            </ToggleButton>
                                        )
                                    })}
                                </ToggleButtonGroup>
                            </Stack>
                        )
                    })}
                </Stack>
            )
            break
        default:
            break
    }

    return (
        <Stack direction="column" sx={sx}>
            <Typography variant={'h6'} style={questionBoldTextStyle}>
                {item.question.text}
            </Typography>
            {answersBody}
        </Stack>
    )
}

export interface Props extends BoxProps {
    item: MatrixTableQuestionUiModel
    onUpdated: (selection: SurveySelection) => void
    style: SurveyStyleUiModel
}

const getButtonColor = (index: number, totalAnswers: number, style: SurveyStyleUiModel) => {
    if (style.primaryColor) return style.primaryColor
    switch (index) {
        case 0:
            return 'green'
        case 1:
            if (totalAnswers === 3) return 'grey'
            else return 'error.main'
        case 2:
            return 'error.main'
        default:
            return 'primary.main'
    }
}
