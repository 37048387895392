export const Defaults = {
    appHistoryState: {
        numberOfLaunches: 0,
        totalSessionDurationSec: 0,
        lastTimeAppOpened: 0,
        lastTimeSurveyTriggered: {},
        userData: {
            userId: '',
            email: '',
            phoneNumber: '',
            tags: {},
            langCode: 'en'
        }
    }
}
