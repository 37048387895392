import { CategoryItem, SurveyModel } from '@feeba/types'
import { UiConfig } from '../survey_renderer'
import { Breakpoint } from '@mui/material'
import { orderedBreakpoints } from '../utils'

export const getSx = (currentIndex: number, itemList: Array<CategoryItem>, surveyUiModel: SurveyModel, uiConfig: UiConfig) => {
    let vertical: Margin = { xs: 2, sm: 4 } // Default margin-top value
    if (surveyUiModel.presentation.style.questionSpacing === 'compact') {
        vertical = { xs: 1, sm: 2 }
        if (uiConfig.enforcedBreakpoint) {
            vertical = findNextBreakpointValue(vertical, uiConfig.enforcedBreakpoint)
        }
    } else if (surveyUiModel.presentation.style.questionSpacing === 'comfortable') {
        vertical = { xs: 2, sm: 4 }
        if (uiConfig.enforcedBreakpoint) {
            vertical = findNextBreakpointValue(vertical, uiConfig.enforcedBreakpoint)
        }
    } else if (surveyUiModel.presentation.style.questionSpacing === 'extended') {
        vertical = { xs: 4, sm: 6 }
        if (uiConfig.enforcedBreakpoint) {
            vertical = findNextBreakpointValue(vertical, uiConfig.enforcedBreakpoint)
        }
    }
    const horizontalMargin = uiConfig.enforcedBreakpoint ? findNextBreakpointValue({ xs: 2, sm: 4 }, uiConfig.enforcedBreakpoint) : { xs: 2, sm: 4 }
    return {
        ml: horizontalMargin,
        mr: horizontalMargin,
        mt: vertical,
        mb: vertical
    }
}

const findNextBreakpointValue = (adaptiveMargin: Margin, enforcedBreakpoint: Breakpoint): number | undefined => {
    // 1. check if the enforcedBreakpoint is set
    // 1.1 if enforcedBreakpoint is set, find the next breakpoint that the enforcedBreakpoint is less than or equal to
    // 2. If enforcedBreakpoint is not set, find the next breakpoint that the current screen width is less than or equal to
    if (!adaptiveMargin) return undefined
    let marginValue: number | undefined = undefined
    const index = orderedBreakpoints.findIndex((bp) => bp === enforcedBreakpoint)
    for (let i = index; i >= 0; i--) {
        if (adaptiveMargin[orderedBreakpoints[i]]) {
            marginValue = adaptiveMargin[orderedBreakpoints[i]]
            break
        }
    }
    console.log('Generating SX::marginValue', marginValue)
    return marginValue
}

type Margin = Partial<Record<Breakpoint, number>> | number | undefined
// type Margin = Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number> | number | undefined
