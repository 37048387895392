import { Logger } from '../../utils/logger'

export function appendQueryParameter(url: string, key: string, value: string): string {
    try {
        let urlObj = new URL(url)
        urlObj.searchParams.append(key, value)
        return urlObj.toString()
    } catch (e) {
        Logger.error(`Failed to append query parameter to url: ${url}, ${e}`)
        return url
    }
}
