import { SurveyModel, SurveySelection } from '@feeba/types'
import { Box, Breakpoint } from '@mui/material'
import { FC, useEffect, useRef, useState } from 'react'
import { SurveyRepresentation } from './Models'
import { publishSurveyResults } from './RestClient'
import { BlockComponent, NavigationType } from './components/BlockComponent'
import { IntegrationMode } from './utils'

/**
 * Renders a survey using the provided survey model and survey presentation.
 * Allows users to navigate through the survey blocks and make selections.
 * Automatically updates the survey results on each selection update.
 */
export const SurveyRenderer: FC<SurveyRendererProps> = (surveyRendererProps) => {
    const [pageState, setPageState] = useState<{ currentBlockIndex: number }>({ currentBlockIndex: 0 })
    const [endSurvey, setEndSurvey] = useState<boolean>(false)
    const selectionRef = useRef(new Map<string, SurveySelection>())

    useEffect(() => {
        ;(window as any).onInlineViewClosed = () => {
            console.log('!!!!--- onInlineViewClosed ---!!!!')
            intermediateUpdate(Array.from(selectionRef.current.values()), surveyRendererProps).catch((err) => {
                console.error(err)
            })
        }

        return () => {
            ;(window as any).onInlineViewClosed = null
        }
    }, [])

    return (
        <Box
            key={`box-component-${pageState.currentBlockIndex}`}
            sx={{
                bgcolor: '#FFFFFF',
                width: '100%',
                overflow: 'hidden',
                overflowY: 'scroll'
            }}
            alignItems="center"
            height={1}
        >
            <BlockComponent
                key={`block-component-${pageState.currentBlockIndex}`}
                categoryUiModel={surveyRendererProps.surveyModel.items[pageState.currentBlockIndex]}
                surveyUiModel={surveyRendererProps.surveyModel}
                // setEndSurvey={() => setEndSurvey(true)}
                endSurvey={endSurvey}
                uiConfig={surveyRendererProps.uiConfig}
                onNavigation={(type, alreadyHandled) => {
                    if (alreadyHandled === false) {
                        if (type === NavigationType.Next) {
                            // if this is a last black, then we need to set the endSurvey to true
                            if (pageState.currentBlockIndex === surveyRendererProps.surveyModel.items.length - 1) {
                                setEndSurvey(true)
                            } else {
                                setPageState({ ...pageState, currentBlockIndex: pageState.currentBlockIndex + 1 })
                            }
                        } else if (type === NavigationType.Previous) {
                            setPageState({ ...pageState, currentBlockIndex: pageState.currentBlockIndex - 1 })
                        }
                    }
                    surveyRendererProps.onSurveyContentChange?.(surveyRendererProps.surveyModel)
                    intermediateUpdate(Array.from(selectionRef.current.values()), surveyRendererProps).catch((err) => {
                        console.error(err)
                    })
                }}
                onSelectionUpdate={(selections) => {
                    selections.forEach((e) => selectionRef.current.set(e.questionId, e))

                    console.log('after onSelectionUpdate() selections::', selectionRef)
                    console.log('SurveySelections::', selections)
                }}
            />
        </Box>
    )
}

function intermediateUpdate(selections: SurveySelection[], surveyRendererProps: SurveyRendererProps): Promise<void> {
    console.log('intermediateUpdate', selections)
    const { projectName, surveyId, sessionId } = surveyRendererProps
    return publishSurveyResults(projectName, surveyId, sessionId, selections)
}

export interface SurveyRendererProps {
    surveyPresentation: SurveyRepresentation
    surveyModel: SurveyModel
    projectName: string
    surveyId: string
    lang: string
    sessionId: string
    uiConfig: UiConfig
    onSurveyContentChange?: (surveyModel: SurveyModel) => void
}

export interface UiConfig {
    integrationMode: IntegrationMode
    enforcedBreakpoint?: Breakpoint
}
