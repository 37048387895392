import { RuleSet, RuleType, SurveyPresentation, getSurveyDelaySec } from '@feeba/types'
import { LocalStateHolder } from './data'
import { GenericAppLifecycle } from './lifecycle'
import { SurveyViewController } from './modal'
import { Logger } from './utils'

const PREFIX_DELAYED_TASK_PAGE = 'OnPage:'
const PREFIX_DELAYED_TASK_EVENT = 'OnEvent:'

enum AppVisibility {
    Backgrounded,
    Foregrounded
}

export class StateManager {
    private surveyController: SurveyViewController | null = null
    private delayedTasks: { [key: string]: NodeJS.Timeout } = {}
    private appVisibility = AppVisibility.Backgrounded

    constructor(
        private lifecycle: GenericAppLifecycle,
        private localStateHolder: LocalStateHolder
    ) {}

    showEventSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        Logger.debug('StateManager::showEventSurvey')
        this.internalShowSurvey(presentation, ruleSet, `${PREFIX_DELAYED_TASK_EVENT}${associatedKey}`)
    }

    showPageSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        Logger.debug('StateManager::showPageSurvey')
        this.internalShowSurvey(presentation, ruleSet, `${PREFIX_DELAYED_TASK_PAGE}${associatedKey}`)
    }

    private internalShowSurvey(presentation: SurveyPresentation, ruleSet: RuleSet, associatedKey: string) {
        const surveyDelay = getSurveyDelaySec(ruleSet)
        if (surveyDelay > 0) {
            Logger.debug(`StateManager::showSurvey - Scheduling survey for ${surveyDelay} Sec`)
            const timeoutId = setTimeout(() => {
                Logger.debug('StateManager::showSurvey - Executing scheduled survey')
                this.initializeSurveyViewController(presentation, ruleSet)
            }, surveyDelay * 1000)
            this.delayedTasks[associatedKey] = timeoutId
        } else {
            Logger.debug('StateManager::showSurvey - Showing survey immediately')
            this.initializeSurveyViewController(presentation, ruleSet)
        }
    }

    private async initializeSurveyViewController(presentation: SurveyPresentation, ruleSet: RuleSet) {
        // if (this.appVisibility === AppVisibility.Backgrounded) {
        //     Logger.debug('StateManager::showSurvey - App is in background, skipping')
        //     return
        // }
        const surveyViewController = new SurveyViewController(
            presentation,
            ruleSet,
            await this.localStateHolder.readAppHistoryState(),
            () => {
                Logger.debug('SurveyViewController::onSurveyWasShown')
            },
            () => {
                Logger.debug('SurveyViewController::onSurveyWasDismissed')
                this.surveyController = null
            }
        )
        this.surveyController = surveyViewController
        surveyViewController.start(this.lifecycle)
    }

    pageClosed(pageName: string) {
        Logger.debug(`StateManager::pageClosed -> ${pageName}`)
        this.cancelPendingSurveys(pageName, RuleType.SCREEN)
        if (this.surveyController) {
            this.surveyController.destroy()
            this.surveyController = null
        }
    }

    cancelEventRelatedSurveys(eventName: string) {
        Logger.debug(`StateManager::cancelEventRelatedSurveys -> ${eventName}`)
    }

    private cancelPendingSurveys(pageName: string, ruleTypeToCancel: RuleType) {
        Logger.debug(`ActivityLifecycleListener::cancelPendingSurveys:: delayedTasks -> ${this.delayedTasks}`)
        const key = ruleTypeToCancel === RuleType.SCREEN ? `${PREFIX_DELAYED_TASK_PAGE}${pageName}` : `${PREFIX_DELAYED_TASK_EVENT}${pageName}`
        const timeoutId = this.delayedTasks[key]
        if (timeoutId) {
            Logger.debug('---> Removing ')
            clearTimeout(timeoutId)
            delete this.delayedTasks[key]
        }
    }

    unload() {
        this.surveyController?.destroy()
    }
}
