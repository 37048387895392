import { AppLifecycleListener, GenericAppLifecycle, OnWindowLifecycleListener } from './generic'

const KEY_FEEBA_INIT = 'feebaInit'
export class WebLifecycleManager implements GenericAppLifecycle {
    private _activityCount: number = 0
    private _activityNameSet = new Set<string>()
    // App State
    private _appStartEventFired: boolean = false
    private _windowLifecycleListener?: OnWindowLifecycleListener
    private _appLifecycleListener?: AppLifecycleListener

    constructor() {
        // if (!sessionStorage.getItem(KEY_FEEBA_INIT)) {
        //     this._appLifecycleListener?.onLifecycleEvent(AppState.CREATED)
        //     sessionStorage.setItem(KEY_FEEBA_INIT, 'true')
        // } else {
        //     window.addEventListener('beforeunload', () => {
        //         this._appLifecycleListener?.onLifecycleEvent(AppState.BACKGROUND)
        //     })
        // }
    }

    windowLifecycleListener(listener: OnWindowLifecycleListener) {
        this._windowLifecycleListener = listener
    }
    appLifecycleListener(listener: AppLifecycleListener) {
        this._appLifecycleListener = listener
    }
}
