import { Box, CircularProgress, Dialog, DialogContent, ThemeProvider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import theme from '../../setup/muijs_theme'
import { fetchSurveyPresentation } from '../../survey_client/RestClient'
import { SurveyRenderer, SurveyRendererProps } from '../../survey_client/survey_renderer'
import { IntegrationMode, parseUrl } from '../../survey_client/utils'
import { uuidv4 } from '../../utils/uuid'
import { Logger } from '../utils'

/**
 * ModalWrapper component renders a modal dialog that displays a survey.
 * It takes the survey URL and a callback function for when the modal is closed as props.
 */
export const WebLibraryModalWrapper: React.FC<Props> = ({ surveyUrl, onClose }) => {
    const [open, setOpen] = React.useState(true)
    const [sessionId] = useState<string>(parseUrl(surveyUrl)?.sessionId || uuidv4())

    const [data, setData] = useState<SurveyRendererProps | null>(null)
    const [error, setError] = useState<{ message: string } | null>(null)

    useEffect(() => {
        const parsed = parseUrl(surveyUrl)
        if (!parsed) {
            console.log('Could not parse the url and retrieeve needed information')
            return
        }
        const { surveyId, projectName, lang, enforcedBreakpoint } = parsed

        // This global object is used to communicate between Survey Web client and integration libraries like Android, iOS, and Web SDK.
        window.Mobile = {
            getPrefetchedSurveyV1: function (projectName: string, surveyId: string, lang: string | undefined): Promise<string> {
                throw new Error('Function not implemented.')
            },
            endOfSurvey: (callToAction: String): void => {
                onClose()
            },
            getCurrentState: function () {
                throw new Error('This function is not needed.')
            },
            surveyFullyRendered: function (): void {
                throw new Error('Function not implemented.')
            },
            resize: function (width: number, height: number): void {
                Logger.warn('resize function is not implemented')
            }
        }
        fetchSurveyPresentation(projectName, surveyId, lang)
            .then((e) => {
                const surveyModel = (e.survey.filter((e) => e.langCode === lang)?.[0] || e.survey[0])!
                setData({ surveyPresentation: e, surveyModel, projectName, surveyId, lang, sessionId, uiConfig: { enforcedBreakpoint, integrationMode: IntegrationMode.Modal } })
                setError(null)
            })
            .catch((err) => {
                setData(null)
                setError({ message: err.message })
            })

        // To avoid any memory leaks, we need to clean up the global object when the component is unmounted.
        return () => {
            window.Mobile = undefined
        }
    }, [])
    useEffect(() => {
        try {
            // Let the client library know that rendering of error is complete
            Mobile?.surveyFullyRendered()
        } catch (e) {
            // no-op
        }
    }, [error])

    const handleClose = () => {
        console.log('ModalWrapper::handleClose')
        setOpen(false)
        onClose()
    }

    console.log('ModalWrapper::data', data)
    return (
        <ThemeProvider theme={theme}>
            <Dialog onClose={handleClose} open={open}>
                <DialogContent sx={{ p: 1 }}>
                    <Box
                        id={'theme-child'}
                        display="flex"
                        justifyContent="center"
                        sx={{ bgcolor: 'lightgrey', alignItems: 'center' }}
                        // onClick={(event) => event.stopPropagation()}
                    >
                        {data ? (
                            <SurveyRenderer {...data} />
                        ) : (
                            <CircularProgress size={24} sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                        )}
                        {/* Shows an error message if there is an error. It is important to render the error message after the survey renderer. Error message takes precedence over the survey renderer. */}
                        {error && (
                            <Box>
                                <Typography>{error.message}</Typography>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

interface Props {
    surveyUrl: string
    onClose: () => void
}
