import { Box, CircularProgress, ThemeProvider, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import theme from '../setup/muijs_theme'
import { fetchSurveyPresentation, publishSurveyResults } from '../survey_client/RestClient'
import { SurveyRenderer, SurveyRendererProps } from '../survey_client/survey_renderer'
import { IntegrationMode, parseUrl } from '../survey_client/utils'
import { uuidv4 } from '../utils/uuid'

export const InlineSurveyView: FC<Props> = ({ url }) => {
    const [open, setOpen] = React.useState(true)
    const [sessionId] = useState<string>(parseUrl(url)?.sessionId || uuidv4())

    const [data, setData] = useState<SurveyRendererProps | null>(null)
    const [error, setError] = useState<{ message: string } | null>(null)

    useEffect(() => {
        const parsed = parseUrl(url)
        if (!parsed) {
            console.log('Could not parse the url and retrieeve needed information')
            return
        }
        const { surveyId, projectName, lang, enforcedBreakpoint } = parsed

        fetchSurveyPresentation(projectName, surveyId, lang)
            .then((e) => {
                const surveyModel = (e.survey.filter((e) => e.langCode === lang)?.[0] || e.survey[0])!
                setData({
                    surveyPresentation: e,
                    surveyModel,
                    projectName,
                    surveyId,
                    lang,
                    sessionId,
                    uiConfig: { enforcedBreakpoint, integrationMode: IntegrationMode.Inline }
                })
                setError(null)
            })
            .catch((err) => {
                setData(null)
                setError({ message: err.message })
            })

        // To avoid any memory leaks, we need to clean up the global object when the component is unmounted.
        return () => {
            publishSurveyResults(projectName, surveyId, sessionId, [])
        }
    }, [])
    useEffect(() => {
        try {
            // Let the client library know that rendering of error is complete
            Mobile?.surveyFullyRendered()
        } catch (e) {
            // no-op
        }
    }, [error])

    return (
        <ThemeProvider theme={theme}>
            <Box
                id={'theme-child'}
                display="flex"
                justifyContent="center"
                sx={{ bgcolor: 'transparent', alignItems: 'center' }}
                // onClick={(event) => event.stopPropagation()}
            >
                {data ? (
                    <SurveyRenderer {...data} />
                ) : (
                    <CircularProgress size={24} sx={{ color: 'primary.main', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                )}
                {/* Shows an error message if there is an error. It is important to render the error message after the survey renderer. Error message takes precedence over the survey renderer. */}
                {error && (
                    <Box>
                        <Typography>{error.message}</Typography>
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    )
}

interface Props {
    url: string
}
