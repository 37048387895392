import { AppHistoryState, SdkResponse } from '@feeba/types'
import { Logger } from '../utils'
import { Defaults } from './defaults'
import { RestClient } from './rest'
import { StateStorageInterface } from './state_storage'

export class LocalStateHolder {
    private lastKnownFeebaConfig?: SdkResponse
    private lastKnownAppHistoryState?: AppHistoryState

    private stateStorage: StateStorageInterface
    private restClient: RestClient
    private eventCountMap = new Map<string, number>()

    constructor(stateStorage: StateStorageInterface, restClient: RestClient) {
        this.stateStorage = stateStorage
        this.restClient = restClient
    }

    forceRefreshFeebaConfig() {
        Logger.debug('LocalStateHolder:: Force refreshing Feeba config')
        this.readAppHistoryState()
            .then((appHistoryState) => this.restClient.getSurveyPlans(appHistoryState))
            .then((response) => this.setFeebaConfig(response))
            .catch((error) => Logger.error(`LocalStateHolder:: Failed to fetch Feeba config. Error:`, error))
    }

    private async setFeebaConfig(response: SdkResponse) {
        Logger.debug('LocalStateHolder:: Storing response: $response')
        // Update local reference
        try {
            console.log('response', response)
            this.responseAvailabilityCallback?.(response)
            this.lastKnownFeebaConfig = response
            this.stateStorage.setFeebaResponse(response)
        } catch (err) {
            Logger.error(`LocalStateHolder:: Failed to parse response. Error: `, err)
        }
    }

    async readLocalConfig(): Promise<SdkResponse> {
        if (!this.lastKnownFeebaConfig) {
            // Read from storage and cache
            this.lastKnownFeebaConfig = await this.stateStorage.getFeebaResponse()
        }
        return Promise.resolve(this.lastKnownFeebaConfig)
    }

    async readAppHistoryState(): Promise<AppHistoryState> {
        let result = this.lastKnownAppHistoryState

        if (!result) {
            try {
                result = await this.stateStorage.getAppHistoryState()
            } catch (error) {
                Logger.warn(`Failed to read local config. Falling back to default state. Error: ${error}`)
                result = Defaults.appHistoryState
            }
        }
        return result
    }

    async login(userId: string, email?: string, phoneNumber?: string) {
        const appHistory = await this.readAppHistoryState()

        appHistory.userData = {
            ...appHistory.userData,
            userId: userId,
            email: email || appHistory.userData.email,
            phoneNumber: phoneNumber || appHistory.userData.phoneNumber
        }

        this.stateStorage.setAppHistoryState(appHistory)
    }

    logout() {
        this.stateStorage.eraseEventAndPageLogs()
    }

    onEvent(eventName: string) {
        this.eventCountMap.set(eventName, (this.eventCountMap.get(eventName) || 0) + 1)
        this.stateStorage.addEventRecord(eventName, '')
    }

    pageOpened(pageName: string) {
        this.stateStorage.addPageOpenRecord(pageName, '')
    }

    pageClosed(pageName: string) {
        //        TODO("Not yet implemented")
    }

    async updateUserData(phoneNumber?: string, email?: string, language?: string, tags?: Record<string, string | number>) {
        const appHistory = await this.readAppHistoryState()

        appHistory.userData = {
            ...appHistory.userData,
            phoneNumber: phoneNumber || appHistory.userData.phoneNumber,
            email: email || appHistory.userData.email,
            langCode: language || appHistory.userData.langCode,
            tags: {
                ...appHistory.userData.tags,
                ...tags
            }
        }

        this.stateStorage.setAppHistoryState(appHistory)
    }

    async addTags(tags: Record<string, string | number>) {
        const appHistory = await this.readAppHistoryState()
        appHistory.userData.tags = {
            ...appHistory.userData.tags,
            ...tags
        }
        this.stateStorage.setAppHistoryState(appHistory)
    }

    private responseAvailabilityCallback: ((config: SdkResponse) => void) | null = null
    onConfigUpdate(callback: ((config: SdkResponse) => void) | null) {
        this.responseAvailabilityCallback = callback
    }
}
