import { Answer, AnswerSelectionType, MultipleChoiceQuestionUiModel, SurveySelection, SurveyStyleUiModel } from '@feeba/types'
import { BoxProps, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { shuffleArray } from '../utils'

export const MultipleChoiceComponent: FC<Props> = ({ item, sx, onUpdated, style }) => {
    const [value, setValue] = useState('none')
    const [answersToRender] = useState<Array<Answer>>(getFinalAnswersToRender(item))
    const [selected, setSelected] = useState(-1)
    const [selectedAnswers, setSelectedAnswers] = useState<Answer[]>([])

    // console.log('MultipleChoiceComponent::item', item)

    const handleCheckboxChange = (index: number, selectedAnswer: Answer, question: MultipleChoiceQuestionUiModel, isChecked: boolean) => {
        let updatedSelectedAnswers: Answer[] = []
        if (isChecked) {
            updatedSelectedAnswers = [...selectedAnswers, selectedAnswer]
        } else {
            updatedSelectedAnswers = selectedAnswers.filter((item) => item !== selectedAnswer)
        }
        setSelectedAnswers(updatedSelectedAnswers)
        onUpdated({ questionId: question.id, answers: updatedSelectedAnswers.map((a) => ({ answerId: a.id })) })
    }

    const handleSingleSelection = (index: number, question: MultipleChoiceQuestionUiModel, answer: Answer) => {
        setSelected(index)
        onUpdated({ questionId: question.id, answers: [{ answerId: answer.id }] })
    }

    const questionBoldTextStyle = {
        fontWeight: style.isQuestionBold ? 'bold' : 'normal', // Set the font weight conditionally
        fontFamily: style.fontFamily,
        fontSize: style.questionFontSize
    }

    const answerBoldTextStyle = {
        fontWeight: style.isAnswerBold ? 'bold' : 'normal',
        fontFamily: style.fontFamily,
        fontSize: style.answerFontSize
    }

    return (
        <Stack direction={'column'} sx={sx}>
            <Typography variant={'h6'} style={questionBoldTextStyle}>
                {item.question.text}
            </Typography>
            <FormControl>
                <RadioGroup
                    name="radio-buttons-group"
                    value={value}
                    onChange={(event) => {
                        console.log('event', event.target.value)
                        setValue((event.target as HTMLInputElement).value)
                    }}
                >
                    {answersToRender.map((a, i) => {
                        return (
                            <FormControlLabel
                                sx={{
                                    borderRadius: 1,
                                    mb: 1,
                                    bgcolor: selected === i || selectedAnswers.includes(a) ? style.primaryColor : '#ffffff'
                                }}
                                value={i}
                                key={i}
                                control={
                                    item.answerType === AnswerSelectionType.Single ? (
                                        <Radio size={'small'} checked={selected === i} onChange={() => handleSingleSelection(i, item, a)} />
                                    ) : (
                                        <Checkbox size={'small'} checked={selectedAnswers.includes(a)} onChange={(e) => handleCheckboxChange(i, a, item, e.target.checked)} />
                                    )
                                }
                                label={
                                    <Typography style={answerBoldTextStyle} color={'textPrimary'}>
                                        {a.text}
                                    </Typography>
                                }
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </Stack>
    )
}
const getFinalAnswersToRender = (item: MultipleChoiceQuestionUiModel): Array<Answer> => {
    let results: Array<Answer> = []
    if (item.answerRandomization.type === 'no') {
        results = item.answers
    } else if (item.answerRandomization.type === 'random_answers') {
        results = shuffleArray(item.answers)
    } else if (item.answerRandomization.type === 'only_answers') {
        results = shuffleArray(item.answers.slice(0, item.answerRandomization.randomizedSubsetSize || item.answers.length))
    }
    return results
}

interface Props extends BoxProps {
    item: MultipleChoiceQuestionUiModel
    onUpdated: (selection: SurveySelection) => void
    style: SurveyStyleUiModel
}
