import { AppHistoryState, SdkResponse } from '@feeba/types'
import { IDBPDatabase, openDB } from 'idb' // Import IndexedDB Promised Library
import { Defaults } from './defaults'
import { EventLog, PageEventLog, StateStorageInterface } from './state_storage'
import { Logger } from '../utils'

const TAG = 'WebStateStorage::'
export class WebStateStorage implements StateStorageInterface {
    private surveyConfigFileName = 'survey_config.json'
    private localStateFileName = 'local_state.json'
    private db: Promise<IDBPDatabase>

    constructor() {
        this.db = openDB('feebaDB', 1, {
            upgrade(db) {
                db.createObjectStore('pages', { keyPath: 'id', autoIncrement: true })
                db.createObjectStore('events', { keyPath: 'id', autoIncrement: true })
            }
        })
    }
    getAppHistoryState(): Promise<AppHistoryState> {
        return new Promise((resolve) => {
            const localValue = localStorage.getItem(this.localStateFileName)
            if (localValue) {
                resolve(JSON.parse(localValue))
            } else {
                resolve(Defaults.appHistoryState) // Replace with your default state
            }
        })
    }

    setAppHistoryState(value: AppHistoryState): void {
        Logger.debug(`${TAG} Setting App History State: ${JSON.stringify(value)}`)
        localStorage.setItem(this.localStateFileName, JSON.stringify(value))
    }

    getFeebaResponse(): Promise<SdkResponse> {
        return new Promise((resolve) => {
            const localValue = localStorage.getItem(this.surveyConfigFileName)
            if (localValue) {
                resolve(JSON.parse(localValue))
            } else {
                Logger.warn(`${TAG} No Feeba Response found in local storage. Returning empty object.`)
                resolve({ surveyPlans: [], inlineSurveys: [] })
            }
        })
    }

    setFeebaResponse(value: SdkResponse): void {
        Logger.debug(`${TAG} Setting Feeba Response: ${JSON.stringify(value)}`)
        localStorage.setItem(this.surveyConfigFileName, JSON.stringify(value))
    }

    async addPageOpenRecord(pageName: string, value: string) {
        const db = await this.db
        db.add('pages', { pageName, value, created: Date.now() })
    }

    async readPageEvenLogs(pageName: string): Promise<PageEventLog[]> {
        const db = await this.db
        const items = await db.getAll('pages')
        return items.filter((item) => item.pageName === pageName)
    }

    async addEventRecord(eventName: string, value: string) {
        const db = await this.db
        db.add('events', { eventName, value, created: Date.now() })
    }

    async readEventLogs(eventName: string): Promise<EventLog[]> {
        const db = await this.db
        const items = await db.getAll('events')
        return items.filter((item) => item.eventName === eventName)
    }

    async trimData() {
        const db = await this.db
        const thirtyDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000
        const pages = await db.getAll('pages')
        const events = await db.getAll('events')

        for (const page of pages) {
            if (page.created < thirtyDaysAgo) {
                db.delete('pages', page.id)
            }
        }

        for (const event of events) {
            if (event.created < thirtyDaysAgo) {
                db.delete('events', event.id)
            }
        }
    }

    async eraseEventAndPageLogs() {
        const db = await this.db
        db.clear('pages')
        db.clear('events')
    }
}
