import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    typography: {
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        primary: {
            light: '#BBDEFB',
            main: '#2196F3',
            dark: '#1976D2',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#00fff0' // very cyan
        },
        divider: '#BDBDBD',
        text: {
            primary: '#212121',
            secondary: '#757575',
            disabled: '#BDBDBD'
        }
        // accent: {

        // }
    }
})

export default theme
