import axios from 'axios'

export const API_URL = `${'https://api.feeba.io'}/v1`

export const webSdkAxiousInstance = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: 'application/json'
    }
})
export function setupAxios(apiUrl: string) {
    console.log(`setupAxios::apiUrl :::: ${apiUrl}`)
    webSdkAxiousInstance.defaults.baseURL = `${apiUrl}/v1`
    webSdkAxiousInstance.defaults.headers.Accept = 'application/json'
}
