export const prepareModalParent = (id: string): HTMLElement => {
    const host: HTMLDivElement = document.createElement('div')
    // Set the background to semi-transparent black to make the modal stand out
    host.id = id
    host.style.position = 'fixed'
    host.style.top = '0'
    host.style.left = '0'
    host.style.width = '0%'
    host.style.height = '0%'
    host.style.display = 'flex'

    // Center the modal
    host.style.justifyContent = 'center'
    host.style.alignItems = 'center'
    host.focus()

    document.body.appendChild(host)
    return host
}
