import { AppHistoryState, RuleSet, SurveyPresentation } from '@feeba/types'
import ReactDOM from 'react-dom/client'
import { GenericAppLifecycle } from '../lifecycle'
import { Logger, appendQueryParameter } from '../utils'
import { WebLibraryModalWrapper } from './WebLibraryModalWrapper'
import { prepareModalParent } from './modalUtils'

export class SurveyViewController {
    private presentation: SurveyPresentation
    private ruleSet: RuleSet
    private appHistoryState: AppHistoryState
    private onSurveyWasShown: () => void
    private onSurveyWasDismissed: () => void

    constructor(presentation: SurveyPresentation, ruleSet: RuleSet, appHistoryState: AppHistoryState, onSurveyWasShown: () => void, onSurveyWasDismissed: () => void) {
        this.presentation = presentation
        this.ruleSet = ruleSet
        this.appHistoryState = appHistoryState
        this.onSurveyWasShown = onSurveyWasShown
        this.onSurveyWasDismissed = onSurveyWasDismissed
    }

    private reactMount: {
        reactParent: HTMLElement
        reactRoot: ReactDOM.Root
    } | null = null

    private

    start(lifecycle: GenericAppLifecycle) {
        const adjustedSurveyUrl = appendQueryParameter(this.presentation.surveyWebAppUrl, 'lang', this.appHistoryState.userData.langCode || 'en')
        Logger.debug('SurveyViewController::start. Rendering survey URL::', this.presentation.surveyWebAppUrl)

        const surveyRootId = 'feeba-modal-backdrop'
        const localRef = prepareModalParent(surveyRootId)
        const root = ReactDOM.createRoot(localRef)

        this.reactMount = {
            reactParent: localRef,
            reactRoot: root
        }

        root.render(
            <WebLibraryModalWrapper
                surveyUrl={adjustedSurveyUrl} // shouldn't have query params here
                onClose={() => {
                    Logger.debug('SurveyViewController::start - onClose')
                    this.destroy()
                    this.onSurveyWasDismissed
                }}
            />
        )
    }

    destroy() {
        if (this.reactMount) {
            this.reactMount.reactRoot?.unmount()
            this.reactMount.reactParent.remove()
            this.reactMount = null
        } else {
            Logger.warn('SurveyViewController::destroy - rectParent is not set')
        }
    }
}
