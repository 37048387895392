import { CategoryItem, CategoryModel, ItemType, SurveyModel, SurveySelection, SurveyType } from '@feeba/types'
import { Box, Button, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { EndSurveyComponent } from './EndSurveyComponent'
import { MatrixTableComponent } from './MatrixTableComponent'
import { MultipleChoiceComponent } from './MultipleChoiceComponent'
import { NpsScoreComponent } from './NpsScoreComponent'
import { getSx } from './question_component_utils'

import { TextEntryComponent } from './TextEntiryComponent'
import { Logger } from '../../wrapper/utils'
import { UiConfig } from '../survey_renderer'
import { IntegrationMode } from '../utils'

const splitCategoryItems = (categoryItems: CategoryItem[]): CategoryItem[][] => {
    const result: CategoryItem[][] = []
    let currentBlock: CategoryItem[] = []
    categoryItems.forEach((e) => {
        if (e.type === ItemType.Divider && e.isSet) {
            result.push(currentBlock)
            currentBlock = []
        } else {
            currentBlock.push(e)
        }
    })
    result.push(currentBlock)
    return result
}

export const BlockComponent: FC<Props> = ({ categoryUiModel, onSelectionUpdate, onNavigation, surveyUiModel, endSurvey, uiConfig }: Props) => {
    const [subBlocks] = useState<CategoryItem[][]>(splitCategoryItems(categoryUiModel.categoryItems))
    const [currentSubBlockIndex, setCurrentSubBlockIndex] = useState<number>(0)
    const selections: SurveySelection[] = []
    const buttonBackgroundColor = '#FDDD34'

    const updateSelections = (selection: SurveySelection) => {
        {
            let found = false
            for (const s of selections) {
                console.log('s.questionId', s.questionId)
                console.log('selection::', selection)
                if (s.questionId === selection.questionId) {
                    if (selection.selections && selection.selections.length > 0 && !s.selections) {
                        // append the new selection to the existing selections
                        s.selections = selection.selections
                    } else if (selection.selections && selection.selections.length > 0 && s.selections && s.selections.length > 0) {
                        // for each  selection in selection.selections if it is not in s.selections then add it
                        selection.selections.forEach((selection) => {
                            if (!s.selections!!.some((s) => s.id === selection.id)) {
                                s.selections!!.push(selection)
                            }
                        })
                    } else {
                        s.answers = selection.answers
                    }
                    found = true
                }
            }
            if (!found) selections.push(selection)
            onSelectionUpdate(selections)
        }
    }

    return (
        <Stack id={'block_container'} direction={'column'} sx={{ height: '100%' }}>
            {subBlocks[currentSubBlockIndex].map((e, i, arr) => {
                if (!endSurvey) {
                    if (e.type === ItemType.MultipleChoice) {
                        return (
                            <MultipleChoiceComponent
                                key={`${currentSubBlockIndex}-mch-${i}`}
                                style={surveyUiModel.presentation.style}
                                item={e}
                                onUpdated={updateSelections}
                                sx={{ ...getSx(i, arr, surveyUiModel, uiConfig) }}
                            />
                        )
                    } else if (e.type === ItemType.TextEntry) {
                        return (
                            <TextEntryComponent
                                key={`${currentSubBlockIndex}-te-${i}`}
                                style={surveyUiModel.presentation.style}
                                item={e}
                                onUpdated={updateSelections}
                                sx={{ ...getSx(i, arr, surveyUiModel, uiConfig) }}
                            />
                        )
                    } else if (e.type === ItemType.NpsScore) {
                        return (
                            <NpsScoreComponent
                                key={`${currentSubBlockIndex}-nps-${i}`}
                                style={surveyUiModel.presentation.style}
                                item={e}
                                onUpdated={updateSelections}
                                sx={{ ...getSx(i, arr, surveyUiModel, uiConfig) }}
                            />
                        )
                    } else if (e.type === ItemType.MatrixTable) {
                        return (
                            <MatrixTableComponent
                                key={`${currentSubBlockIndex}-mt-${i}`}
                                item={e}
                                style={surveyUiModel.presentation.style}
                                onUpdated={updateSelections}
                                sx={{ ...getSx(i, arr, surveyUiModel, uiConfig) }}
                            />
                        )
                    } else if (e.type === ItemType.Divider) {
                        // Divider is not rendered and must not be passed here.
                        Logger.warn(`Divider is not rendered and must not be passed here.`, e)
                        return <div key={i}></div>
                    } else {
                        Logger.error(`Rendering has not been implemented for item =>`, e)
                        return <div key={i}></div>
                    }
                }
            })}

            {categoryUiModel.messageEnd?.visible && endSurvey && (
                <EndSurveyComponent
                    sx={{ width: uiConfig.integrationMode === IntegrationMode.FullScreen ? '100vw' : '100%' }}
                    item={categoryUiModel.messageEnd}
                    style={surveyUiModel.presentation.style}
                />
            )}

            {surveyUiModel.properties.typeConfig?.type !== SurveyType.Inline && !endSurvey && (
                <Box style={{ flex: 1 }} bottom="20px" left="0" right="0" alignItems={'flex-end'} justifyContent={'center'} display={'flex'}>
                    <Button
                        disableElevation={true}
                        sx={{
                            m: 1,
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontSize: '1rem',
                            height: '55px',
                            backgroundColor: buttonBackgroundColor,
                            minWidth: 200,
                            width: uiConfig.integrationMode === IntegrationMode.FullScreen ? '100vw' : '100%',
                            '&:hover': {
                                backgroundColor: buttonBackgroundColor // Set the same background color on hover
                            }
                        }}
                        onClick={() => {
                            if (currentSubBlockIndex === subBlocks.length - 1) {
                                onNavigation(NavigationType.Next, false)
                            } else {
                                setCurrentSubBlockIndex(currentSubBlockIndex + 1)
                                onNavigation(NavigationType.Next, true)
                            }
                        }}
                    >
                        <Typography color={'black'} fontFamily={'-apple-system-subheadline, BlinkMacSystemFont, sans-serif'} variant={'h6'}>
                            {surveyUiModel.nextButtonText?.value ? surveyUiModel.nextButtonText.value : 'Next'}
                        </Typography>
                    </Button>
                </Box>
            )}
        </Stack>
    )
}

export interface Props {
    categoryUiModel: CategoryModel
    surveyUiModel: SurveyModel
    uiConfig: UiConfig
    onSelectionUpdate: (selections: Array<SurveySelection>) => void
    onNavigation: (type: NavigationType, alreadyHandled: boolean) => void
    endSurvey?: boolean
}
export enum NavigationType {
    Next,
    Previous
}
