import { FC, useEffect, useState } from 'react'
// Themeing
import { IntegrationMode, Logger, ParsedUrl, SurveyRenderer, SurveyRendererProps, fetchSurveyPresentation, parseUrl, uuidv4 } from '@feeba/web-sdk'
import theme from '@feeba/web-sdk/src/setup/muijs_theme'
import { Box, ThemeProvider } from '@mui/material'

// Null means error, undefined means not yet loaded
interface PageState {
    error?: string | null | undefined
    data?: SurveyRendererProps | null | undefined
    parsedUrl: ParsedUrl
}
/**
 *  Used by standalong Survey Client Web app that is hosted and used by Android and iOS SDKs via WebView
 */
export const SurveyPage: FC<Props> = ({ url }) => {
    const [state, setState] = useState<PageState | null>(null)
    const [updateState, setUpdateState] = useState(0)

    useEffect(() => {
        const parsedUrl = parseUrl(url)
        if (!parsedUrl) {
            Logger.error('Could not parse the url and retrieeve needed information')
            return
        }
        // Update HTML body in case the integration mode is full screen
        if (parsedUrl.integrationMode === IntegrationMode.FullScreen || parsedUrl.integrationMode === IntegrationMode.Inline) {
            document.body.style.width = '100%'
            document.body.style.maxWidth = '100vw'
        } else if (parsedUrl.integrationMode === IntegrationMode.Modal) {
            if (parsedUrl.maxWidth && !isNaN(Number(parsedUrl.maxWidth))) {
                document.body.style.maxWidth = parsedUrl.maxWidth + 'px'
            }
        }
        setState({ parsedUrl })
        const { surveyId, projectName, lang, sessionId, enforcedBreakpoint } = parsedUrl
        // fetch Survey from the server
        fetchSurveyPresentation(projectName, surveyId, lang)
            .then((e) => {
                const surveyModel = (e.survey.filter((e) => e.langCode === lang)?.[0] || e.survey[0])!
                const surveyRendererProps: SurveyRendererProps = {
                    surveyPresentation: e,
                    surveyModel,
                    projectName,
                    surveyId,
                    lang,
                    uiConfig: { enforcedBreakpoint, integrationMode: parsedUrl.integrationMode || IntegrationMode.FullScreen }, // Default to full screen
                    sessionId: sessionId || uuidv4()
                }
                setState({ data: surveyRendererProps, parsedUrl })
            })
            .catch((err) => {
                Logger.error('Error fetching survey', err)
                setState({ error: err.message, parsedUrl })
            })
    }, [url])

    useEffect(() => {
        try {
            Logger.debug(`SurveyPage::Survey resize(${document.body.clientWidth}, ${document.body.clientHeight})`)
            Mobile && Mobile?.resize(document.body.clientWidth, document.body.clientHeight)
        } catch (_) {}
    }, [updateState])

    useEffect(() => {
        try {
            // Let the client library know that rendering of error is complete
            try {
                Logger.debug(`SurveyPage::Survey resize(${document.body.clientWidth}, ${document.body.clientHeight})`)
                Mobile && Mobile?.surveyFullyRendered()
                Mobile && Mobile?.resize(document.body.clientWidth, document.body.clientHeight)
            } catch (_) {}
        } catch (_) {}
    }, [state?.error, state?.data])

    console.log('SurveyPage::maxWidth', state?.parsedUrl.maxWidth)
    return (
        <ThemeProvider theme={theme}>
            {state?.data ? (
                <Box
                    id={'theme-child'}
                    display="flex"
                    justifyContent="center"
                    sx={{ bgcolor: 'lightgrey', alignItems: 'center', height: state.parsedUrl.integrationMode === IntegrationMode.FullScreen ? '100vh' : undefined }}
                    onClick={(event) => event.stopPropagation()}
                >
                    <SurveyRenderer {...state.data} onSurveyContentChange={() => setUpdateState(updateState + 1)} />
                </Box>
            ) : (
                <></>
            )}
        </ThemeProvider>
    )
}

interface Props {
    url: string
}
