import { AppHistoryState, SdkResponse } from '@feeba/types'
import { AxiosResponse } from 'axios'
import { webSdkAxiousInstance } from '../../setup/SetupAxios'
import { Feeba } from '../feeba'
import { Logger } from '../utils'

export class RestClient {
    async getSurveyPlans(state: AppHistoryState): Promise<SdkResponse> {
        Logger.debug('RestClient::fetchSurveyPlans....')
        try {
            const response = await this.sendPostRequest<SdkResponse>('/v1/survey/sdk/list', JSON.stringify(state.userData))
            Logger.debug(`RestClient::plans -> ${response}`)
            return response
        } catch (t) {
            Logger.warn(`RestClient::getSurveyPlans failed: ${t}`)
            throw t
        }
    }

    private async sendPostRequest<T>(path: string, jsonInputString: string): Promise<T> {
        const requestUrl = `${Feeba.config.serviceConfig.hostUrl}${path}`
        Logger.debug(`RestClient::sendPostRequest -> ${requestUrl}`)
        Logger.debug(`RestClient::auth headers -> ${Feeba.config.serviceConfig.apiToken}`)

        try {
            const response: AxiosResponse = await webSdkAxiousInstance.post<T>(requestUrl, jsonInputString, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': Feeba.config.serviceConfig.apiToken
                }
            })

            if (response.status === 200) {
                return response.data as T
            } else {
                throw new Error(`POST request failed with response code: ${response.status}`)
            }
        } catch (error) {
            throw new Error(`POST request failed: ${error}`)
        }
    }
}
