import { FeebaConfig, SdkResponse } from '@feeba/types'
import { setupAxios } from '../setup/SetupAxios'
import { LocalStateHolder, RestClient } from './data'
import { WebStateStorage } from './data/web_storage'
import { WebLifecycleManager } from './lifecycle'
import { StateManager } from './state_manager'
import { TriggerValidator } from './trigger_validator'
import { Logger } from './utils'

let feebaState:
    | {
          config: FeebaConfig
          localStateHolder: LocalStateHolder
          stateManager: StateManager
          triggerValidator: TriggerValidator
      }
    | undefined

export const Feeba = {
    config: feebaState?.config!,
    init: (apiToken: string, langCode: string = 'en', hostUrl?: string): boolean => {
        if (feebaState) {
            Logger.warn('Feeba already initialized')
            return false
        }
        // try {
        const apiHost = hostUrl || 'https://api.feeba.io'
        Feeba.config = { serviceConfig: { apiToken, hostUrl: apiHost, langCode } }
        console.log(`Feeba:init: ${apiHost}`)
        setupAxios(apiHost)
        // Initialize core components
        let localStateHolder = new LocalStateHolder(new WebStateStorage(), new RestClient())
        localStateHolder.forceRefreshFeebaConfig()
        let stateManager = new StateManager(new WebLifecycleManager(), localStateHolder)

        feebaState = {
            config: Feeba.config,
            localStateHolder,
            stateManager,
            triggerValidator: new TriggerValidator()
        }
        Logger.debug('Feeba initialized')
        return true
        // } catch (error) {
        //     Logger.error(`Failed to initialize Feeba: ${error}`)
        //     return false
        // }
    },
    triggerEvent(eventName: string, value?: string) {
        if (!feebaState) {
            Logger.error('Feeba not initialized')
            return
        }
        Logger.debug(`onEvent -> ${eventName}, value: ${value}`)
        feebaState.localStateHolder.onEvent(eventName)
        // check if we have a survey for this event
        feebaState.triggerValidator
            .onEvent(eventName, value, feebaState.localStateHolder)
            .then((validatorResult) => {
                if (validatorResult) {
                    feebaState?.stateManager.showEventSurvey(validatorResult.surveyPresentation, validatorResult.ruleSet, eventName)
                } else {
                    Logger.warn('No survey found for event:', eventName)
                }
            })
            .catch((error) => {
                Logger.error(`Failed to validate event trigger: ${error}`)
            })
    },
    unload() {
        Logger.error('Feeba:unload:: You must not call this function unless you are testing Feeba!')
        if (!feebaState) {
            Logger.warn('Feeba is not initialized. Inccorrect ')
        }
        feebaState?.stateManager.unload()
        feebaState = undefined
    },
    pageOpened(pageName: string) {
        Logger.debug(`Feeba:pageOpened triggered -> ${pageName}`)
        if (!feebaState) {
            Logger.error('Feeba not initialized')
            return
        }
        feebaState.localStateHolder.pageOpened(pageName)
        // Check if we have a survey for this page
        const result = feebaState.triggerValidator
            .pageOpened(pageName, feebaState.localStateHolder)
            .then((result) => {
                if (result) {
                    feebaState?.stateManager.showPageSurvey(result.surveyPresentation, result.ruleSet, pageName)
                } else {
                    Logger.warn('No survey found for page:', pageName)
                }
            })
            .catch((error) => {
                Logger.error(`Failed to trigger pageOpened: ${error}`)
            })
    },
    pageClosed(pageName: string) {
        Logger.debug(`Feeba:pageClosed triggered -> ${pageName}`)
        if (!feebaState) {
            Logger.error('Feeba not initialized')
            return
        }
        try {
            feebaState.localStateHolder.pageClosed(pageName)
            // Trigger PageClose to ensure if there is any delayed survey to cancel
            feebaState.stateManager.pageClosed(pageName)
        } catch (error) {
            Logger.error(`Failed to trigger pageClosed: ${error}`)
        }
    },
    onConfigUpdate(callback: ((config: SdkResponse) => void) | null) {
        feebaState?.localStateHolder?.onConfigUpdate(callback)
    },
    User: {
        login: (userId: string, email?: string, phoneNumber?: string) => {},
        logout: () => {
            feebaState?.localStateHolder.logout()
        },
        addPhoneNumber: (phoneNumber: string) => {},
        addEmail: (email: string) => {},
        /**
         * Set the language of the user. This will be used to filter surveys.
         * language: ISO 639-1 code
         */
        setLanguage: (language: string) => {
            feebaState?.localStateHolder.updateUserData(undefined, undefined, language)
        },
        addTag: (tags: Record<string, string | number>) => {
            feebaState?.localStateHolder.addTags(tags)
        }
    }
}
