import { setupAxios } from '@feeba/web-sdk'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './setup.css'
import { SurveyPage } from './survey_page'

setupAxios(process.env.REACT_APP_API_URL || 'http://localhost:8080')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <React.StrictMode>
        <SurveyPage url={window.location.href} />
    </React.StrictMode>
)
