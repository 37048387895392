import { Breakpoint } from '@mui/material'
import { ObjectID } from '../utils/uuid'

/**
 * Shuffles the elements of an array randomly. All elements are shuffled in place.
 *
 * @param array - The array to be shuffled.
 * @returns The shuffled array.
 */
export const shuffleArray = (array: Array<any>) => {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

/**
 * Expected URL path ending format: /projectName/surveyId
 * Expected Query parameters:
 * - lang: language code, default is 'en'
 * - sessionId: unique session ID, default is generated using ObjectID()
 * - im: integration mode, available modes are. If omitted, default is full screen
 *     - f: full screen
 *     - m: modal
 *     - i: inline
 */
export const parseUrl = (url: string): ParsedUrl | null => {
    let urlObject
    try {
        urlObject = new URL(url)
    } catch (error) {
        // console.error('Error parsing URL', error)
        return null
    }

    const paths = urlObject.pathname.split('/')

    console.log('-------------------- paths', paths)
    // Read the last path from the url
    const surveyId = paths.pop()
    const projectName = paths.pop()
    if (!surveyId) {
        console.error('Invalid survey ID')
        return null
    }
    if (!projectName) {
        console.error('Invalid project name')
        return null
    }

    const lang = urlObject.searchParams.get('lang') || 'en'
    const sessionId = urlObject.searchParams.get('sessionId') || ObjectID()
    const integrationMode = mapIntegrationMode(urlObject.searchParams.get('im'))
    const enforcedBreakpoint = parseBreakpoint(urlObject.searchParams.get('bp'))

    const maxWidth = urlObject.searchParams.get('mxw')

    return { sessionId, surveyId, projectName, lang, integrationMode, enforcedBreakpoint, maxWidth }
}

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px
export const orderedBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const

const parseBreakpoint = (bp: string | null): Breakpoint | undefined => {
    switch (bp) {
        case 'xs':
            return 'xs'
        case 'sm':
            return 'sm'
        case 'md':
            return 'md'
        case 'lg':
            return 'lg'
        case 'xl':
            return 'xl'
        default:
            return undefined
    }
}

export interface ParsedUrl {
    sessionId: string
    surveyId: string
    projectName: string
    lang: string
    integrationMode: IntegrationMode | null
    enforcedBreakpoint?: Breakpoint
    maxWidth?: string // CSS width value
}
export enum IntegrationMode {
    FullScreen = 'f',
    Modal = 'm',
    Inline = 'i'
}
const mapIntegrationMode = (mode: string | null): IntegrationMode | null => {
    switch (mode) {
        case 'f':
            return IntegrationMode.FullScreen
        case 'm':
            return IntegrationMode.Modal
        case 'i':
            return IntegrationMode.Inline
        default:
            return null
    }
}
